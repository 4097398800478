export default defineNuxtRouteMiddleware(to => {
  const guards = [
    // {
    //   key: 'EXAMPLE_FEATURE',
    //   guard: (path: string) => {
    //     return path.startsWith('/example-feature-path');s
    //   },
    // },
    {
      key: 'DOCS',
      guard: (path: string) => {
        return path.startsWith('/docs');
      },
    },
    {
      key: 'GROUP',
      guard: (path: string) => {
        return path.startsWith('/docs');
      },
    },
    {
      key: 'USER',
      guard: (path: string) => {
        return path.startsWith('/docs');
      },
    },
  ];

  // Check if the route is guarded by a feature flag
  for (const guard of guards) {
    if (guard.guard(to.path)) {

      // If the feature flag is disabled, abort the navigation
      const enabled = useFeatureFlag(guard.key);

      console.log(`Guarding route "${to.path}" with feature flag "${guard.key}" [enabled: ${enabled}]`);

      if (!enabled) {
        return abortNavigation();
      }
    }
  }

  return true;
});
