export default defineNuxtRouteMiddleware(async to => {
  if (to.path === '/') {
    return navigateTo({ path: '/dashboard', query: to.query }, { redirectCode: 302 });
  }

  const redirects = {
    '/course-library': '/courses',
    '/enroll': '/enroll/basic-monthly',
    '/enroll/pro': '/enroll/pro-yearly',
    '/user/login': '/auth/login',
    '/user/register': '/auth/signup',
    '/user/password': '/auth/forgot-password',
  };

  if (Object.keys(redirects).includes(to.path)) {
    const redirectPath = redirects[to.path as keyof typeof redirects];
    return navigateTo({ path: redirectPath, query: to.query }, { redirectCode: 301 });
  }

  // If no page is found, check if there is a redirect
  if (to.matched.length === 0) {
    const path = to.path;
    const url = useApiUrl('/redirects?source=' + encodeURIComponent(path));

    try {
      const redirectResponse = await useAuthenticatedFetchSimplified<{destination: string}>(url);

      if (redirectResponse.destination) {
        return navigateTo({ path: redirectResponse.destination, query: to.query }, { redirectCode: 301 });
      }
    } catch (e) {
      console.error('error fetching redirects', e);
      showError({ statusCode: 404, statusMessage: 'Page not found' });
    }
  }
});
