export default defineNuxtRouteMiddleware(() => {
  const { user } = useUserStore();

  if (!user.value) {
    return;
  }

  const data = {
    event: 'mm_ga4_get_user_data',
    user: {
      login_status: 'logged_in',
      registration_status: 'registered',
      user_country: user.value.country,
      user_profession: user.value.profession,
      user_professional_track: user.value.professionalTrack,
      plan_type: user.value.planType,
    },
  } as { event: string; user: Record<string, string>; user_id?: string };

  if (user.value.traceId) {
    data.user_id = user.value.traceId;
  }

  useNuxtApp().$gtmPush(data);
});
