import { defineNuxtPlugin } from 'nuxt/app';

declare global {
  interface Window { dataLayer: any[] }
}

export default defineNuxtPlugin(() => {
  window.dataLayer = window.dataLayer || [];

  function gtmPush (msg: any) {
    window.dataLayer.push(msg);
  }

  return {
    provide: {
      gtmPush: (msg: any) => {
        gtmPush(msg);
      },

      /**
       * @deprecated (this is triggered automatically by GTM itself)
       */
      page_view: () => {
        gtmPush({
          event: 'page_view',
        });
      },

      ai_prompt_user_selected_role: (selectedRoleName: string) => {
        gtmPush({
          event: 'ai_prompt_user_selected_role',
          selectedRoleName,
        });
      },

      ai_prompt_user_copied_prompt: (selectedRoleName: string) => {
        gtmPush({
          event: 'ai_prompt_user_copied_prompt',
          selectedRoleName,
        });
      },

      ai_prompt_user_started_over: (selectedRoleName: string) => {
        gtmPush({
          event: 'ai_prompt_user_started_over',
          selectedRoleName,
        });
      },

      ai_prompt_user_opened_additional_settings: (selectedRoleName: string) => {
        gtmPush({
          event: 'ai_prompt_user_opened_additional_settings',
          selectedRoleName,
        });
      },
    },
  };
});