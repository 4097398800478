/**
 * Returns the value of a feature flag from the cookie if it exists.
 * @param flagName The name of the feature flag
 * @returns The value of the feature flag
 */
export const useFeatureFlag = (flagName: string) => {
  const cookie = useCookie('featureFlags') as Record<string, any>;

  if (
    !cookie.value ||
      !cookie.value[flagName] ||
      cookie.value[flagName] === undefined
  ) {
    console.log(`Feature flag ${flagName} does not exist`);
    return false;
  }

  return cookie.value[flagName];
};
