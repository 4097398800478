import { default as professional_45infoMjbDvhZWSvMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/professional-info.vue?macro=true";
import { default as profile_45infobGXMBCNZZKMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/profile-info.vue?macro=true";
import { default as subscriptionu6BGPIqNbPMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/subscription.vue?macro=true";
import { default as about_45youJM3z5bDF1KMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/about-you.vue?macro=true";
import { default as forgot_45passwordNKRCiBhxIdMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/forgot-password.vue?macro=true";
import { default as login103yOntRZlMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/login.vue?macro=true";
import { default as logoutqIAm7RUQ0AMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/logout.vue?macro=true";
import { default as reset_45passwordOmvMP187YjMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/reset-password.vue?macro=true";
import { default as signupmpIopPFtlAMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/signup.vue?macro=true";
import { default as verify_45emailZmh0dQ0x1VMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/verify-email.vue?macro=true";
import { default as indexYmU1bHOs5uMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/comparison/index.vue?macro=true";
import { default as _91lessonId_93JrMFO0Va84Meta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/lessons/[lessonId].vue?macro=true";
import { default as _91quizId_93irpicLxwCrMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/quizzes/[quizId].vue?macro=true";
import { default as _91courseId_93FQDpCNkBTwMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/[courseId].vue?macro=true";
import { default as indexvwLW6cOZ6kMeta } from "/opt/atlassian/pipelines/agent/build/pages/courses/index.vue?macro=true";
import { default as dashboardaGDr6YjHlOMeta } from "/opt/atlassian/pipelines/agent/build/pages/dashboard.vue?macro=true";
import { default as _91planGroup_93DSLUUe1ydzMeta } from "/opt/atlassian/pipelines/agent/build/pages/enroll/[planGroup].vue?macro=true";
import { default as thank_45you0JUjaIXH86Meta } from "/opt/atlassian/pipelines/agent/build/pages/enroll/thank-you.vue?macro=true";
import { default as acceptTKWDsb6WcUMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/[groupId]/invite/accept.vue?macro=true";
import { default as indexf1v6euLwGxMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/index.vue?macro=true";
import { default as _91memberId_93Ts2YmnoXOlMeta } from "/opt/atlassian/pipelines/agent/build/pages/groups/members/[memberId].vue?macro=true";
import { default as pricingevsz0GYkgYMeta } from "/opt/atlassian/pipelines/agent/build/pages/pricing.vue?macro=true";
import { default as ai_45prompting_45tooldJiq69lQ6XMeta } from "/opt/atlassian/pipelines/agent/build/pages/tools/ai-prompting-tool.vue?macro=true";
import { default as _91webinar_93UGPsQNVSzFMeta } from "/opt/atlassian/pipelines/agent/build/pages/webinars/[webinar].vue?macro=true";
import { default as indexCOFIk3Py82Meta } from "/opt/atlassian/pipelines/agent/build/pages/webinars/index.vue?macro=true";
export default [
  {
    name: professional_45infoMjbDvhZWSvMeta?.name ?? "account-professional-info",
    path: professional_45infoMjbDvhZWSvMeta?.path ?? "/account/professional-info",
    meta: professional_45infoMjbDvhZWSvMeta || {},
    alias: professional_45infoMjbDvhZWSvMeta?.alias || [],
    redirect: professional_45infoMjbDvhZWSvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/professional-info.vue").then(m => m.default || m)
  },
  {
    name: profile_45infobGXMBCNZZKMeta?.name ?? "account-profile-info",
    path: profile_45infobGXMBCNZZKMeta?.path ?? "/account/profile-info",
    meta: profile_45infobGXMBCNZZKMeta || {},
    alias: profile_45infobGXMBCNZZKMeta?.alias || [],
    redirect: profile_45infobGXMBCNZZKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/profile-info.vue").then(m => m.default || m)
  },
  {
    name: subscriptionu6BGPIqNbPMeta?.name ?? "account-subscription",
    path: subscriptionu6BGPIqNbPMeta?.path ?? "/account/subscription",
    meta: subscriptionu6BGPIqNbPMeta || {},
    alias: subscriptionu6BGPIqNbPMeta?.alias || [],
    redirect: subscriptionu6BGPIqNbPMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: about_45youJM3z5bDF1KMeta?.name ?? "auth-about-you",
    path: about_45youJM3z5bDF1KMeta?.path ?? "/auth/about-you",
    meta: about_45youJM3z5bDF1KMeta || {},
    alias: about_45youJM3z5bDF1KMeta?.alias || [],
    redirect: about_45youJM3z5bDF1KMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/about-you.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordNKRCiBhxIdMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordNKRCiBhxIdMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordNKRCiBhxIdMeta || {},
    alias: forgot_45passwordNKRCiBhxIdMeta?.alias || [],
    redirect: forgot_45passwordNKRCiBhxIdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: login103yOntRZlMeta?.name ?? "auth-login",
    path: login103yOntRZlMeta?.path ?? "/auth/login",
    meta: login103yOntRZlMeta || {},
    alias: login103yOntRZlMeta?.alias || [],
    redirect: login103yOntRZlMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutqIAm7RUQ0AMeta?.name ?? "auth-logout",
    path: logoutqIAm7RUQ0AMeta?.path ?? "/auth/logout",
    meta: logoutqIAm7RUQ0AMeta || {},
    alias: logoutqIAm7RUQ0AMeta?.alias || [],
    redirect: logoutqIAm7RUQ0AMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordOmvMP187YjMeta?.name ?? "auth-reset-password",
    path: reset_45passwordOmvMP187YjMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordOmvMP187YjMeta || {},
    alias: reset_45passwordOmvMP187YjMeta?.alias || [],
    redirect: reset_45passwordOmvMP187YjMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupmpIopPFtlAMeta?.name ?? "auth-signup",
    path: signupmpIopPFtlAMeta?.path ?? "/auth/signup",
    meta: signupmpIopPFtlAMeta || {},
    alias: signupmpIopPFtlAMeta?.alias || [],
    redirect: signupmpIopPFtlAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailZmh0dQ0x1VMeta?.name ?? "auth-verify-email",
    path: verify_45emailZmh0dQ0x1VMeta?.path ?? "/auth/verify-email",
    meta: verify_45emailZmh0dQ0x1VMeta || {},
    alias: verify_45emailZmh0dQ0x1VMeta?.alias || [],
    redirect: verify_45emailZmh0dQ0x1VMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: _91courseId_93FQDpCNkBTwMeta?.name ?? "courses-courseId",
    path: _91courseId_93FQDpCNkBTwMeta?.path ?? "/courses/:courseId()",
    meta: _91courseId_93FQDpCNkBTwMeta || {},
    alias: _91courseId_93FQDpCNkBTwMeta?.alias || [],
    redirect: _91courseId_93FQDpCNkBTwMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId].vue").then(m => m.default || m),
    children: [
  {
    name: indexYmU1bHOs5uMeta?.name ?? "courses-courseId-comparison",
    path: indexYmU1bHOs5uMeta?.path ?? "comparison",
    meta: indexYmU1bHOs5uMeta || {},
    alias: indexYmU1bHOs5uMeta?.alias || [],
    redirect: indexYmU1bHOs5uMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/comparison/index.vue").then(m => m.default || m)
  },
  {
    name: _91lessonId_93JrMFO0Va84Meta?.name ?? "courses-courseId-lessons-lessonId",
    path: _91lessonId_93JrMFO0Va84Meta?.path ?? "lessons/:lessonId()",
    meta: _91lessonId_93JrMFO0Va84Meta || {},
    alias: _91lessonId_93JrMFO0Va84Meta?.alias || [],
    redirect: _91lessonId_93JrMFO0Va84Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/lessons/[lessonId].vue").then(m => m.default || m)
  },
  {
    name: _91quizId_93irpicLxwCrMeta?.name ?? "courses-courseId-quizzes-quizId",
    path: _91quizId_93irpicLxwCrMeta?.path ?? "quizzes/:quizId()",
    meta: _91quizId_93irpicLxwCrMeta || {},
    alias: _91quizId_93irpicLxwCrMeta?.alias || [],
    redirect: _91quizId_93irpicLxwCrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/[courseId]/quizzes/[quizId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvwLW6cOZ6kMeta?.name ?? "courses",
    path: indexvwLW6cOZ6kMeta?.path ?? "/courses",
    meta: indexvwLW6cOZ6kMeta || {},
    alias: indexvwLW6cOZ6kMeta?.alias || [],
    redirect: indexvwLW6cOZ6kMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardaGDr6YjHlOMeta?.name ?? "dashboard",
    path: dashboardaGDr6YjHlOMeta?.path ?? "/dashboard",
    meta: dashboardaGDr6YjHlOMeta || {},
    alias: dashboardaGDr6YjHlOMeta?.alias || [],
    redirect: dashboardaGDr6YjHlOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91planGroup_93DSLUUe1ydzMeta?.name ?? "enroll-planGroup",
    path: _91planGroup_93DSLUUe1ydzMeta?.path ?? "/enroll/:planGroup()",
    meta: _91planGroup_93DSLUUe1ydzMeta || {},
    alias: _91planGroup_93DSLUUe1ydzMeta?.alias || [],
    redirect: _91planGroup_93DSLUUe1ydzMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/enroll/[planGroup].vue").then(m => m.default || m)
  },
  {
    name: thank_45you0JUjaIXH86Meta?.name ?? "enroll-thank-you",
    path: thank_45you0JUjaIXH86Meta?.path ?? "/enroll/thank-you",
    meta: thank_45you0JUjaIXH86Meta || {},
    alias: thank_45you0JUjaIXH86Meta?.alias || [],
    redirect: thank_45you0JUjaIXH86Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/enroll/thank-you.vue").then(m => m.default || m)
  },
  {
    name: acceptTKWDsb6WcUMeta?.name ?? "groups-groupId-invite-accept",
    path: acceptTKWDsb6WcUMeta?.path ?? "/groups/:groupId()/invite/accept",
    meta: acceptTKWDsb6WcUMeta || {},
    alias: acceptTKWDsb6WcUMeta?.alias || [],
    redirect: acceptTKWDsb6WcUMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/[groupId]/invite/accept.vue").then(m => m.default || m)
  },
  {
    name: indexf1v6euLwGxMeta?.name ?? "groups",
    path: indexf1v6euLwGxMeta?.path ?? "/groups",
    meta: indexf1v6euLwGxMeta || {},
    alias: indexf1v6euLwGxMeta?.alias || [],
    redirect: indexf1v6euLwGxMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: _91memberId_93Ts2YmnoXOlMeta?.name ?? "groups-members-memberId",
    path: _91memberId_93Ts2YmnoXOlMeta?.path ?? "/groups/members/:memberId()",
    meta: _91memberId_93Ts2YmnoXOlMeta || {},
    alias: _91memberId_93Ts2YmnoXOlMeta?.alias || [],
    redirect: _91memberId_93Ts2YmnoXOlMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/groups/members/[memberId].vue").then(m => m.default || m)
  },
  {
    name: pricingevsz0GYkgYMeta?.name ?? "pricing",
    path: pricingevsz0GYkgYMeta?.path ?? "/pricing",
    meta: pricingevsz0GYkgYMeta || {},
    alias: pricingevsz0GYkgYMeta?.alias || [],
    redirect: pricingevsz0GYkgYMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: ai_45prompting_45tooldJiq69lQ6XMeta?.name ?? "tools-ai-prompting-tool",
    path: ai_45prompting_45tooldJiq69lQ6XMeta?.path ?? "/tools/ai-prompting-tool",
    meta: ai_45prompting_45tooldJiq69lQ6XMeta || {},
    alias: ai_45prompting_45tooldJiq69lQ6XMeta?.alias || [],
    redirect: ai_45prompting_45tooldJiq69lQ6XMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/tools/ai-prompting-tool.vue").then(m => m.default || m)
  },
  {
    name: _91webinar_93UGPsQNVSzFMeta?.name ?? "webinars-webinar",
    path: _91webinar_93UGPsQNVSzFMeta?.path ?? "/webinars/:webinar()",
    meta: _91webinar_93UGPsQNVSzFMeta || {},
    alias: _91webinar_93UGPsQNVSzFMeta?.alias || [],
    redirect: _91webinar_93UGPsQNVSzFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webinars/[webinar].vue").then(m => m.default || m)
  },
  {
    name: indexCOFIk3Py82Meta?.name ?? "webinars",
    path: indexCOFIk3Py82Meta?.path ?? "/webinars",
    meta: indexCOFIk3Py82Meta || {},
    alias: indexCOFIk3Py82Meta?.alias || [],
    redirect: indexCOFIk3Py82Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webinars/index.vue").then(m => m.default || m)
  }
]