import type { CmsMedia } from '~/composables/useCmsMedia';

export enum CONTENT_TYPE {
  CHAPTER = 'chapter',
  COURSE = 'course',
  CASE = 'case',
  LESSON = 'lesson',
  QUIZ = 'quiz',
  KICKOFF_QUIZ = 'kickoff_quiz',
  MASTERY_QUIZ = 'mastery_quiz',
  COMPARISON = 'comparison',
}

export const COMPARISON_ID = -1000;

export interface CourseComparison {
  questions: Array<{
    id: number;
    title: string;
    type: string;
    isKickOffCorrect: boolean;
    isMasteryCorrect: boolean;
    answers: {
      id: number;
      answer: string;
      isCorrect: boolean;
    }[];
    feedbackVideo?: CmsMedia;
  }>;
  stats: {
    kickOffTotal: string;
    masteryTotal: string;
    total: string;
    knowledgeIncreasePercentage: number;
  };
}

export interface KickoffQuizQuestion {
  question: string;
  answers: Array<{
    id: number;
    title: string;
    isSelected: boolean;
  }>;
  isCorrect: boolean;
  correctAnswers: number[];
}