// import { defineNuxtPlugin } from 'nuxt/app';
// import type { AwsRumConfig } from 'aws-rum-web';
// import { AwsRum } from 'aws-rum-web';

export default defineNuxtPlugin(() => {
  // try {
  //   if (window.location.hostname === 'localhost') {
  //     console.debug('AWS RUM is disabled on localhost');
  //     return;
  //   }

  //   const config: AwsRumConfig = {
  //     sessionSampleRate: 0.5,
  //     guestRoleArn: "arn:aws:iam::251389961193:role/RUM-Monitor-eu-west-1-251389961193-4756968640071-Unauth",
  //     identityPoolId: "eu-west-1:cc8cf3e4-16fe-475c-9edf-f628fad3ab9c",
  //     endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
  //     telemetries: ["performance","errors","http"],
  //     allowCookies: false,
  //     enableXRay: false,
  //   };

  //   const APPLICATION_ID: string = 'fd9cadc8-3f23-4faf-b3c5-a3aeb58d62b4';
  //   const APPLICATION_VERSION: string = '1.0.0';
  //   const APPLICATION_REGION: string = 'eu-west-1';

  //   new AwsRum(
  //     APPLICATION_ID,
  //     APPLICATION_VERSION,
  //     APPLICATION_REGION,
  //     config,
  //   );
  // } catch (error) {
  //   // Ignore errors thrown during CloudWatch RUM web client initialization
  // }
});